.form-div{
    width: 100%;
    height: 100%;
    align-self: center;
}
.navbar-logo{
    background-color:#3f51b5 !important;
    margin: 1vh 0.5vw;

    & :hover{
        cursor: pointer;
    }
}
.toolbar{
    padding-left: 0.3% !important;
    padding-right: 0.3% !important;
}
.title{
    flex-grow: 1;
}