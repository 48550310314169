.navbar{
    flex-grow: 1;
}
.user-icon{
    margin-right: 1vh;
}

.title{
    flex-grow: 1;
}
.home-button{
    color: rgb(255, 255, 255) !important
}
.toolbar{
    padding-left: 0.3% !important;
    padding-right: 0.3% !important;
}
.navbar-logo{
    background-color:#3f51b5 !important;
    margin: 1vh 0.5vw;

    & :hover{
        cursor: pointer;
    }
}
