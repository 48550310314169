.board{
    flex-grow: 1;
}
.board-content{
    margin-bottom: 60px;
    margin-top: 1vh;
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 165px;
}
.category{
    padding: 0 1vw;
    margin-top: 1vh;
}

.category-title{
    width: 100%;
    // margin-top: 1vh;
    display: inline-flex;
    padding: 1vh 1vw;
}
.category-title-text{
    margin-left: 0.5vw !important;
    flex-grow: 1;
}
.add-button{
    display: inline-flex;
    button{
        margin: 0;
        padding: 0;
    }
}
.heading-well-icon{
        color: rgb(28, 175, 23);
}
.heading-action-icon{
    color: rgb(56, 212, 240);
}
.heading-appreciation-icon{
    color: rgb(218,165,32)

}
.toolbar-box{
    margin-top: 65px;
    padding: 0.5%;
    border-bottom-color: rgb(194, 194, 194) !important;
}
.popper{
    border: 1px solid;
    padding: 1vh;
    background-color: white;
}
.safety-result-info{
    margin-left: 0.5vw;
}
.safety-graph{
    padding-top:1vh;
    margin-top: .5vh;
}
.safety-chat-popper{
    z-index: 1;
}


.safety-success{
    width: 220px;
    float: left !important;
    border-color: rgb(69, 199, 80) !important;
    color: rgb(69, 199, 80) !important;
}
.safety-failure{
    width: 220px;
    float: left !important;
    border-color: rgb(209, 73, 73) !important;
    color: rgb(209, 73, 73) !important;
}

.presentation-button-group {
    margin-right: 0.5vw; 
}