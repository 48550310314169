.main {
    flex-grow: 1;
    width:100%;
    height: 100%;
    padding: 46px 65px;
    z-index: 10;
    flex-wrap: nowrap !important;
}

.row{
    align-items: center;
}

.main-image{
    width: 35vw;
}

.form{
    height: 100%;
    width: 400px;
    background: white;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: space-evenly;
    padding: 1.5rem 30px;
    position: relative;
    margin: 20px;
    gap: 1.5rem
}

.loading{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    opacity: 0.7;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: white;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 9;
    margin-top: 0 !important;
}
.loading-no-display{
    display: none;
}

.form > *{
    margin-top: 30px ;
}

.join-text{
    text-align: center;
    color: #5a5858;
}

.input{
    margin-top: 50px !important;
}

.button{
    height: 50px;
    background-color: #3E64C7;
}

.caption{
    text-align: center;
    margin-bottom: 20px !important;
}

.ocean {
    z-index: -5;
    height: 20%;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background: #FF6584;
}

.wave {
    z-index: -5;
    background: url(../../assets/waves.svg) repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 250px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
    z-index: -5;
    top: -175px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 0.7;
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}

@keyframes swell {
    0%, 100% {
        transform: translate3d(0,-25px,0);
    }
    50% {
        transform: translate3d(0,5px,0);
    }
}
