.board-info{
    width: '100%';
    border-radius: 0 !important;
}

.board-info{
    flex-grow: 1;
}

.board-info-heading{
    display: inline;
}

.export-button{
    text-align: right !important;
    flex-grow: 1;
}

.export-icon{
    color: rgb(52, 231, 97)
}

.list-item-text{
  text-transform: capitalize;
}

.board-button-group{
    color: rgb(255, 255, 255) !important;
    border-color: rgb(255, 255, 255) !important;
    font-size: 8.5pt !important;
}
.feedback-button{
    color: #ebc856 !important;
    size: 10pt !important;
}