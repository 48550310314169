.custom-footer{
    top: auto !important;
    bottom: 0;
    height: 45px;
    flex-grow: 1;
}
.branding-text{
    color:#5a5858;
    flex-grow: 1;
    margin-left: 2vh !important;
    margin-bottom: 1.35em !important;
}
.feedback-text{
    color:#5a5858;
    margin-right: 2vh !important;
    margin-bottom: 1.35em !important;
}
.toolbar{
    padding-left: 0.3% !important;
    padding-right: 0.3% !important;
}